.project__filters {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 0.75rem;
  margin-bottom: var(--mb-2);
}

.project__item {
  color: var(--title-color);
  padding: 0.25rem 0.75rem;
  font-weight: var(--font-medium);
  border-radius: 0.5rem;
  text-transform: capitalize;
}

.project__item:hover {
  background-color: var(--title-color);
  color: var(--container-color);
}

.project__container {
  grid-template-columns: repeat(2, max-content);
  gap: 3rem;
  justify-content: center;
}

.project__card {
  display: flex;
  flex-direction: column;
  background-color: var(--container-color);
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 1.25rem;
  border-radius: 1rem;
  max-width: 400px;
}

.project__media {
  display: flex;
  justify-content: center;
}

.project__img {
  /*width: 295px; */
  height: 200px;
  width: auto;
  border-radius: 1rem;
  margin-bottom: var(--mb-1);
}

.project__video {
  height: 200px;
  width: auto;
  margin-bottom: var(--mb-1);
}

.project__title {
  text-align: center;
  font-size: var(--normal-font-size);
  font-weight: var(--font-medium);
  margin-bottom: var(--mb-0-5);
}

.project__description {
  color: var(--text-color);
  font-size: var(--small-font-size);
  margin-bottom: var(--mb-0-5);
}

.project__buttons-container {
  display: flex;
  flex-direction: row;
  margin-top: auto;
}

.project__button-source {
  align-self: flex-start;
}

.project__button-site {
  margin-left: auto;
  align-self: flex-end;
}

.project__button {
  color: var(--title-color);
  font-size: var(--small-font-size);
  display: flex;
  align-items: center;
  column-gap: 0.25rem;
}

.project__button-icon {
  font-size: 1rem;
  transition: 0.4s;
}

.project__button:hover {
  color: var(--title-color-dark);
}

.project__button:hover .project__button-icon {
  transform: translateX(0.25rem);
  color: var(--title-color-dark);
}

.tech__stack {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-bottom: var(--mb-0-5);
}

.tech__stack-item {
  display: flex;
  align-items: center;
}

.tech__stack-img {
  height: 20px;
  width: 20px;
}

.tech__stack-skill {
  font-size: var(--small-font-size);
  color: var(--text-color);
  margin-left: 0.2rem;
}

/* ACTIVE PROJECT */
.active__project {
  background-color: var(--title-color);
  color: var(--container-color);
}

/* BREAK POINTS */
@media screen and (max-width: 992px) {
  .project__container {
    gap: 1.25rem;
  }

  .project__card {
    padding: 1rem;
  }

  .project__img {
    margin-bottom: 0.75rem;
  }

  .project__title {
    margin-bottom: 0.25rem;
  }

  .project__description {
    margin-bottom: 0.25rem;
  }

  .tech__stack {
    margin-bottom: 0.25rem;
  }
}

@media screen and (max-width: 768px) {
  .project__container {
    grid-template-columns: max-content;
  }
}

@media screen and (max-width: 576px) {
  .project__container {
    grid-template-columns: 1fr;
  }

  .project__img {
    width: 100%;
    height: auto;
  }

  .project__video {
    width: 100%;
    height: auto;
  }
}

@media screen and (max-width: 350px) {
  .project__item {
    font-size: var(--small-font-size);
  }

  .project__filters {
    column-gap: 0.25rem;
  }
}
