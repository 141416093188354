.skills__container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.skills__container .skill {
  flex: 0 0 calc(50% - 3rem); /* 50% for two items per row, 3rem for the gap */
  margin: 1.5rem; /* half of the gap */
}

.skills__content {
  background-color: var(--container-color);
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 2rem 4rem;
  border-radius: 1.25rem;
}

.skills__title {
  font-size: var(--normal-font-size);
  font-weight: var(--font-medium);
  text-align: center;
  margin-bottom: var(--mb-1-5);
}

.skills__box {
  display: flex;
  justify-content: center;
  column-gap: 2.5rem;
}

.skills__group {
  display: grid;
  align-items: flex-start;
  row-gap: 1rem;
}

.skills__data {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
}

.skills svg {
  font-size: 1rem;
  color: var(--title-color);
}

.skills__name {
  font-size: var(--normal-font-size);
  font-weight: var(--font-medium);
}

.skills__icon {
  width: 30px;
  height: 30px;
}

/* BREAK POINTS */
@media screen and (max-width: 992px) {
  .skills__container {
    grid-template-columns: max-content;
    row-gap: 2rem;
  }
}

@media screen and (max-width: 576px) {
  .skills__container {
    grid-template-columns: 1fr;
  }

  .skills__content {
    padding: 1.5rem;
  }

  .skills__name {
    font-size: var(--small-font-size);
  }

  .skills__icon {
    width: 20px;
    height: 20px;
  }
}

@media screen and (max-width: 350px) {
  .skills__box {
    column-gap: 1.25rem;
  }
}
